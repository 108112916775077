<template>
  <v-layout class="page-container" justify-center align-center>
    <div class="background layer-1"></div>
    <div class="background layer-2"></div>
    <v-card class="card elevation-24">
      <v-card-text class="justify-center pa-12">
        <v-row>
          <v-col cols="12" sm="5" md="6" class="d-flex flex-column justify-center align-center">
            <img :src="BG" class="bg">
          </v-col>
          <v-col cols="12" sm="7" md="6" class="d-flex flex-column justify-center align-center">
            <div class="pb-6">
              <span style="font-weight: 800; font-size: 2.5em; color: #242d34;">Sign In to</span>
            </div>
            <div class="pb-8">
              <img :src="Logo" style="height: 60px">
            </div>
            <div class="pb-12" style="width: 100%; max-width: 250px;">
              <v-divider class="app-divider"></v-divider>
            </div>
            <v-alert class="mt-0 mb-3" transition="slide-y-transition" dismissible color="error" v-model="tokenInvalid">
              {{errorMessage}}
            </v-alert>
            <v-btn @click="submit"
              :loading="loading"
              outlined
              class="py-3"
              style="height: auto; width: 100%; max-width: 250px; border-radius: 1.25em; text-transform: none; color: rgba(5, 5, 5, 0.24);"
            >
              <img :src="GoogleIcon" style="width: 2em;" />
              <div class="ml-8 grey--text text-caption font-weight-bold">
                Continue with Google
              </div>
            </v-btn>
          </v-col>
        </v-row>
        <div class="">
        </div>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
import UserService from '../services/user.service'
import BG from '!!url-loader!@/assets/signin-bg.jpg'
import Logo from '!!url-loader!@/assets/logo.png'
import GoogleIcon from '!!url-loader!@/assets/google.png'

export default {
  data: () => ({
    BG,
    Logo,
    GoogleIcon,
    valid: true,
    tokenInvalid: false,
    errorMessage: '',
    loading: false,
  }),
  methods: {
    async submit () {
      this.tokenInvalid = false
      this.loading = true
      let valid = true
      try {
        valid = await UserService.login()
        this.tokenInvalid = false
      } catch (e) {
        console.error(e)
        valid = false
        if (!e.error && e !== false) {
          this.tokenInvalid = true
          this.errorMessage = e.message
        }
      }
      if (valid && !this.tokenInvalid) {
        this.$router.replace(this.$route.query.redirect || '/')
      } else {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    &.layer-1 {
      background: #D7D4F4;
      background: linear-gradient(to right bottom, #b3d0ca, #e5e5e5, #d5cfb8);
    }
    &.layer-2 {
      background: #D7D4F4;
      background: linear-gradient(to right top, rgba(255,255,255, 0) 50%,rgba(255,255,255, 1) 100%);
    }
  }
  .page-container {}
  .card {
    width: 90%;
    max-width: 1024px;
    border-radius: 30px;
    background: rgba(255,255,255, 1) !important;
    box-shadow:  29px 29px 57px rgba(0,0,0,.1), -29px -29px 57px rgba(255,255,255,.1) !important;
  }
  .bg {
    width: 100%;
    opacity: .6;
  }

  @media screen and (max-width: 599px) {
    .card {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      padding: 0em !important;
      box-shadow: none !important;
      border-radius: 0 !important;
    }
    .bg {
      width: 80%;
    }
  }
</style>

